/* eslint-disable react/jsx-props-no-spreading */
import { Dispatch, FunctionComponent, ReactNode, SetStateAction, useEffect, useRef } from 'react'
import { css, cx } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useSwipeable } from 'react-swipeable'
import { useWindowSize } from '@/libs/helpers/hooks/useWindowSize'
import SvgIcon from '@/tbui/SvgIcon'
import { useClickOutsideEvent } from '@/tbui/hooks/useClickOutsideEvent'
import Overlay from '../../Overlay'
import Text from '../../Text'

const slideUpBodyStyles = (theme: Theme): string => css`
  display: none;
  @media (max-width: ${theme.breakpoints.SM}) {
    display: block;
    background: ${theme.palette.WHITE};
    border-top-right-radius: ${theme.shape.RADIUS};
    border-top-left-radius: ${theme.shape.RADIUS};
    overflow: hidden;
    max-height: 100vh;
    bottom: -100vh;
    transition: 0.25s ease;
    position: fixed;
    height: auto;
    right: 0;
    left: 0;
    padding: 25px 16px 20px;

    &.is-open {
      bottom: 0vh;
    }
  }
`

const headerStyles = css`
  position: sticky;
  display: grid;
  top: 0;
  z-index: 10;
  padding: 0 1rem 1rem;
  background-color: white;
`

const StyledHomeIndicatorButton = styled.button`
  background: ${({ theme }) => theme.palette.GRAY_2};
  border-radius: 6px;
  margin: 0.5rem auto;
  border: none;
  width: 125px;
  height: 6px;
`

const StyledText = styled(Text)`
  width: 100%;
  word-break: break-all;
`

interface SlideUpProps {
  classNames?: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  title: string | null
  subtitle: string | null
  showCloseButton?: boolean
  showIndicator?: boolean
  name: string
  children: ReactNode
  shouldCloseOnClickOutside?: boolean
}

const SlideUp: FunctionComponent<SlideUpProps> = ({
  classNames,
  children,
  open,
  setOpen,
  title,
  subtitle,
  showCloseButton = false,
  showIndicator = false,
  name,
  shouldCloseOnClickOutside = true,
}) => {
  const theme = useTheme()
  const windowSize = useWindowSize()
  const handleSwipeActions = useSwipeable({
    onSwipedDown: () => setOpen(false),
    swipeDuration: 250, // prevents swipe down action when scrolling body
  })
  const slideUpBodyRef = useRef<HTMLDivElement>(null)
  useClickOutsideEvent(slideUpBodyRef, () => {
    if (shouldCloseOnClickOutside) {
      setOpen(false)
    }
  })

  useEffect(() => {
    // Dismiss overlay on breakpoint above SM
    if (
      open &&
      windowSize != null &&
      windowSize.width != null &&
      windowSize.width > theme.breakpointValues.SM
    ) {
      setOpen(false)
    }
  }, [open, setOpen, theme.breakpointValues.SM, windowSize])

  return (
    <Overlay open={open}>
      <div
        data-test="slide-up-body"
        className={cx(slideUpBodyStyles(theme), classNames, {
          'is-open': open,
        })}
        {...handleSwipeActions}
        // order matters here as per https://github.com/FormidableLabs/react-swipeable#how-to-share-ref-from-useswipeable
        ref={slideUpBodyRef}
        id={name}
      >
        <header className={headerStyles}>
          {showIndicator ? (
            <StyledHomeIndicatorButton type="button" onClick={() => setOpen(false)} />
          ) : null}
          {showCloseButton ? (
            <SvgIcon
              color="PRIMARY"
              className="close-button"
              fontSize="24px"
              name="close"
              onClick={() => setOpen(false)}
            />
          ) : null}
          {title != null ? (
            <StyledText type="H3" align="left" m="0 0 12px 0">
              {title}
            </StyledText>
          ) : null}
          {subtitle != null ? (
            <StyledText type="P" align="left" m="0 0 12px 0">
              {subtitle}
            </StyledText>
          ) : null}
        </header>
        <main>{children}</main>
      </div>
    </Overlay>
  )
}

export { SlideUp }
